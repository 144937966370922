var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"audio-manager"},[(false)?_c('button',{on:{"click":function($event){return _vm.avancar()}}},[_vm._v(" avançar ")]):_vm._e(),_c('div',{staticClass:"audio-buttons"},[_c('div',{staticClass:"audio-button",on:{"click":function($event){return _vm.toggleBackgroundAudio()}}},[_c('i',{staticClass:"fa",class:_vm.backgroundAudio ? 'fa-volume-up' : 'fa-volume-off'})]),_c('div',{staticClass:"audio-button",on:{"click":function($event){return _vm.toggleContainer()}}},[_c('i',{staticClass:"fas fa-cog"})])]),(_vm.showContainer)?_c('div',{staticClass:"audio-manager-wrapper",on:{"click":function($event){return _vm.toggleContainer()}}}):_vm._e(),(_vm.showContainer)?_c('div',{staticClass:"audio-manager-container"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.backgroundVolume),expression:"backgroundVolume"}],attrs:{"type":"range","id":"volumeRange","min":"0","max":"1","step":"0.1","value":"0.5"},domProps:{"value":(_vm.backgroundVolume)},on:{"input":function($event){return _vm.changeBackgroundVolume($event)},"__r":function($event){_vm.backgroundVolume=$event.target.value}}})]):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"volumeRange"}},[_c('p',[_vm._v("Background:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('audio',{attrs:{"id":"menu-hover-audio"}},[_c('source',{attrs:{"src":require("../assets/audio/menu-hover.mp3"),"type":"audio/mp3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('audio',{attrs:{"id":"select-audio"}},[_c('source',{attrs:{"src":require("../assets/audio/select.mp3"),"type":"audio/mp3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('audio',{attrs:{"id":"background-audio"}},[_c('source',{attrs:{"src":require("../assets/audio/background.mp3"),"type":"audio/mp3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('audio',{attrs:{"id":"switch-audio"}},[_c('source',{attrs:{"src":require("../assets/audio/switch.mp3"),"type":"audio/mp3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('audio',{attrs:{"id":"type-audio"}},[_c('source',{attrs:{"src":require("../assets/audio/type.mp3"),"type":"audio/mp3"}})])
}]

export { render, staticRenderFns }